import * as React from "react";
import Env from "../../.env.js";
import Topbar from "../../components/Topbar/index";
import FormStepCounter from "../../components/FormStepCounter";
import { withLanguage } from "../../providers/LanguageContext";
import { withContext } from "../../providers/UserContext";
import phoneMyst from "../../resources/phoneMyst.svg";
import phoneSelego from "../../resources/phoneSelego.svg";
import PhoneGlitch from "../../resources/phoneGlitch.svg";
import "./styles.scss";
import {
  CancelSubForm,
  CancelSubSetp2,
  CancelSubStep3,
} from "../../components/CancelSub/index";
import { useLocation } from "react-router-dom";
import UserServices from "../../services/UserServices";

function CancelSubScreen(props) {
  const { translate, history } = props;
  const stepState = React.useState(0);
  const [step, setStep] = stepState;
  const [uid, setUid] = React.useState();
  const [os, setOs] = React.useState();
  const [formData, setFormData] = React.useState();
  const steps = [
    <CancelSubForm
      onSubmit={(data) => {
        setFormData(data);
        setStep((prevState) => prevState + 1);
      }}
      uid={uid}
    />,
    <CancelSubSetp2 onSubmit={() => onSubmit()} />,
    <CancelSubStep3 />,
  ];
  const { search } = useLocation();

  const onSubmit = async () => {
    try {
      const lastSubscription = props.user.data.last_subscription;
      if (lastSubscription) {
        await UserServices.cancelPlan(
          lastSubscription.payment_platform_id,
          formData
        );

        if (os) {
          window.open(
            os === "ios"
              ? "https://apps.apple.com/account/subscriptions"
              : "https://play.google.com/store/account/subscriptions"
          );
        }
      }

      setStep((prevState) => prevState + 1);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const searhUrl = new URLSearchParams(search);
    const searchUid = searhUrl.get("uid");
    if (props.user.data) {
      const { uid, subscribed_at } = props.user.data;
      setUid(uid);
      if (subscribed_at && subscribed_at.toLowerCase() !== "stripe")
        setOs(subscribed_at.toLowerCase());
    } else if (searchUid) {
      setUid(searchUid);
      setOs(searhUrl.get("os"));
    } else history.push("/");
  }, []);

  return (
    <div className="container-cancelSub">
      <Topbar />
      <div className="px-12 lg:px-24 py-4">
        <FormStepCounter state={stepState} totalSteps={3} />
        <div className="flex">
          <div className="w-full lg:w-8/12">{steps[step]}</div>
          <img
            src={
              Env.env === "glitch"
                ? PhoneGlitch
                : Env.env === "selego"
                ? phoneSelego
                : phoneMyst
            }
            className="hidden lg:block"
            alt="Myst screenshot"
          />
        </div>
        <div className="flex justify-between">
          <span className=" text-white text-xs font-light">
            <a
              className="mr-1 text-white text-xs font-light"
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn3.themyst.com/TermsandConditions.html"
            >
              {translate("termsAndUse")}
            </a>
            {" & "}
            <a
              className="ml-1 text-white text-xs font-light"
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn3.themyst.com/privacy.html"
            >
              {translate("privacyPolicy")}
            </a>
          </span>
          <span>{translate("tribesMediaBV")}</span>
        </div>
      </div>
    </div>
  );
}

export default withContext(withLanguage(CancelSubScreen));
