import { useHistory } from "react-router-dom";
import { withContext } from "../../providers/UserContext";
import Env from "../../.env.js";

function CancelSubStep3({ translate, loadUserData }) {
  const history = useHistory();

  const onClick = () => {
    loadUserData();
    history.push("/#openSignup");
  };

  return (
    <div className="my-12">
      <h1 className="text-white font-bold my-4 mt-12">{translate("sorry")}</h1>
      <p className="text-white">{translate("cancelSubStep3Msg")}</p>
      <button
        onClick={() => onClick()}
        className={`uppercase ${
          Env.env === "glitch" ? "" : "text-white"
        } primary-background-color px-3 py-3 my-5`}
      >
        {translate("resubscribe")}
      </button>
    </div>
  );
}

export default withContext(CancelSubStep3);
