import React, { useContext } from "react";
import "./styles.scss";
import Topbar from "../../components/Topbar/index";
import MainItem from "../../components/MainItem/index";
import Author from "../../components/Author/index";
import { Row, Col, Container } from "react-bootstrap";
import AudioServices from "../../services/AudioServices";
import { withLanguage } from "../../providers/LanguageContext";
import { Dropdown, AudiosList } from "../../tribes-media-ui/components";
import AudioContext, { useAudioPlayer } from "../../providers/AudioContext";
import { withContext } from "../../providers/UserContext";
import { useModals } from "../../providers/ModalsContext";
import { useHistory } from "react-router-dom";
import { logScreenView } from "../../utils/analyticsEvents";
import { AuthServices } from "../../services/AuthServices";

class DetailAudio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainItem: null,
    };
  }

  async componentDidMount() {
    try {
      const audioResponse = await AudioServices.getAudio(
        this.props.match.params.id
      );
      const audio = audioResponse.data;
      console.log("audio", audio);

      logScreenView({
        name: `${audio.contentType} - ${audio.contentId}/${audio.titleEN} - Content View`,
      });

      this.setState({
        mainItem: {
          image: audio.imageDHigh,
          imageMobile: audio.imageMHigh,
          imageDetailsMobile: audio.imageMDetails,
          categoryES: audio.tagES,
          categoryEN: audio.tagEN,
          titleEN: audio.titleEN,
          titleES: audio.titleES,
          contentType: audio.contentType,
          author: [audio.director1, audio.director2, audio.director3],
          year: audio.year,
          resumeEN: audio.descriptionEN,
          resumeES: audio.descriptionES,
          minutes: audio.minutes,
          audio: audio.audio?.join(", "),
          cc: audio.CC,
          parts: audio.parts || audio.seasons,
          editorialEN: audio.editorialEN,
          editorialES: audio.editorialES,
          id: audio.id,
        },
      });
    } catch (err) {
      console.log("-----> ", err);
      this.props.history.push("/404");
    }
  }
  render() {
    return (
      <div className="container-list-movies">
        <Topbar />
        {this.state.mainItem !== null && (
          <>
            <MainItem item={this.state.mainItem} type="detail-audio" />
            <Container fluid="lg" className="no-padding-responsive">
              <Row>
                <Col className="author-col">
                  <Author editorial={this.state.mainItem} />
                </Col>
              </Row>

              <PartsList parts={this.state.mainItem.parts} />
              {/* {Env.env !== "glitch" && this.state.mainItem.cast && (
                <Row className="cast-section">
                  <Col className="cast-col">
                    <Cast
                      title={this.props.translate("castAndCrew")}
                      cast={this.state.mainItem.cast}
                    />
                  </Col>
                </Row>
              )} */}
            </Container>
          </>
        )}
        ;
      </div>
    );
  }
}

function _PartsList(props) {
  const history = useHistory();
  const { parts, currentLanguage, user, region, translate } = props;
  const { onToggle } = useModals();
  const [filterLatest, setFilterLatest] = React.useState(false);
  const [selectedPart, setSelectedPart] = React.useState("0");
  const { onSelect } = useAudioPlayer(user);

  const listItems =
    parts?.[selectedPart]?.chapters || parts?.[selectedPart]?.episodes;

  const list = listItems?.map((chapter) => ({
    // title: `${translate('episode')} ${chapter.number}`,
    subTitle: chapter[`title${currentLanguage.toUpperCase()}`],
    image: chapter.image,
    url: chapter.manifestURL,
    id: chapter.contentId,
  }));
  const options = parts.map((part, index) => ({
    label: `${translate("part")} ${part.number}`,
    value: index.toString(),
  }));

  const _list = filterLatest ? list.reverse() : list;

  async function _onSelect(audio) {
    const response = await AuthServices.getCountryPlans(region);
    if (!response.enabled) {
      history.push("/unavailable");
    } else if (user.data?.active) {
      onSelect(audio);
    } else if (!user.auth) {
      onToggle("openLogin");
    } else {
      onToggle("openSignup");
    }
  }

  return (
    <div className="mt-4 audios-list-container">
      <div className="flex justify-between items-center">
        {parts.length > 1 ? (
          <Dropdown
            options={options}
            onChange={(val) => setSelectedPart(val.value)}
            value={selectedPart}
          />
        ) : (
          <React.Fragment />
        )}
        <button
          onClick={() => setFilterLatest(!filterLatest)}
          className="audio-list-filter"
        >
          {filterLatest
            ? translate("oldestEpisodes")
            : translate("latestEpisodes")}
        </button>
      </div>
      <Row className="cast-section">
        <Col className="cast-col">
          <AudiosList list={_list} onClick={_onSelect} />
        </Col>
      </Row>
    </div>
  );
}

const PartsList = withContext(withLanguage(_PartsList));

export default withLanguage(withContext(DetailAudio));
