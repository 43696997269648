import axios, { AxiosResponse } from "axios";
import Env from "../../src/.env.js";
import { EXPIRED_TOKEN, INVALID_TOKEN } from "../models/constants/LoginErrors";

export const genericGet = (
  query: string,
  region?: string,
  params?: any,
  token?: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .get(Env.api_url + query + (region ? `&country_code=${region}` : ""), {
        params: params ? params : undefined,
        headers: {
          Authorization: token ?? "",
        },
      })
      .then((response) => {
        if (response && response.data) {
          resolve(response);
        } else {
          reject();
        }
      })
      .catch((error) => {
        if (
          error.response?.data?.error === INVALID_TOKEN ||
          error.response?.data?.error === EXPIRED_TOKEN
        ) {
          localStorage.removeItem("userAuth");
          window.location.href = "/";
        }
        reject(error);
      });
  });
};

export const genericPost = (
  query: string,
  region?: string,
  params?: any,
  token?: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .post(
        Env.api_url + query + (region ? `&country_code=${region}` : ""),
        params ? params : undefined,
        {
          headers: {
            Authorization: token ?? "",
          },
        }
      )
      .then((response) => {
        if (response?.status >= 200 && response?.status < 300) {
          //204 data without content
          if(response?.data !== undefined || response?.status === 204){
            resolve(response);
          }else{
            reject(new Error("Response without content"))
          }
        } else {
          reject(new Error("Error in the response"));
        }
      })
      .catch((error) => {
        if (
          error.response?.data?.error === INVALID_TOKEN ||
          error.response?.data?.error === EXPIRED_TOKEN
        ) {
          localStorage.removeItem("userAuth");
          window.location.href = "/";
        }
        reject(error);
      });
  });
};

export const genericPatch = (
  query: string,
  region?: string,
  params?: any,
  token?: string
) => {
  return new Promise<AxiosResponse>((resolve, reject) => {
    return axios
      .patch(
        Env.api_url + query + (region ? `&country_code=${region}` : ""),
        params ? params : undefined,
        {
          headers: {
            Authorization: token ?? "",
          },
        }
      )
      .then((response) => {
        if (response && response.data) {
          resolve(response);
        } else {
          reject();
        }
      })
      .catch((error) => {
        if (
          error.response?.data?.error === INVALID_TOKEN ||
          error.response?.data?.error === EXPIRED_TOKEN
        ) {
          localStorage.removeItem("userAuth");
          window.location.href = "/";
        }
        reject(error);
      });
  });
};
