import Button from "../../Button";
import Env from "../../../../.env.js";
import { withContext } from "../../../../providers/UserContext";

function PlansFlow(props) {
  const { labels, plans, colorFlow, currentLanguage, handleSelectPlan } = props;
  console.log("🚀 ~ PlansFlow ~ currentLanguage:", currentLanguage)

  const orderPlans = plans.sort((plan) => (plan.interval === "year" ? 1 : -1)).filter((p) => p.interval !== "promo");
  return (
    <div>
      <div className={`create ${colorFlow}`}>{labels.choosePlan}</div>
      <div className={`join ${colorFlow}`}>{labels.join}</div>
      <div className="plans-flow text-center">
        {orderPlans.map((plan) => (          
          <div className="mrg-btn" key={`plan-${plan?.product_id}`}>
            <span className="plans-flow-interval-text">{plan?.interval}</span>

            <span className="plans-flow-price-text">
              {plan[`${currentLanguage}`]}
            </span>

            {plan[`subtitle_${currentLanguage.toLowerCase()}`]
              ?.split("+")
              ?.filter(Boolean)
              ?.map((feature) => (
                <span className="plans-flow-feature-text">+ {feature}</span>
              ))}

            <Button
              className={`btn-flow plans-flow-button-${
                Env.env === "myst" ? "white" : "black"
              }`}
              onClick={() => handleSelectPlan(plan.product_id)}
              label={currentLanguage === "es" ? "Continuar" : "Continue"}
            />

            {plan.interval === "year" && (
              <span
                className={`best-offer ${Env.env === "myst" && "text-white"}
                ${Env.env === "selego" && "change-color"}`}
              >
                {labels.bestOffer}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default withContext(PlansFlow);
