import { useState, useContext, createContext, useEffect } from "react";
import AudioPlayer from "../tribes-media-ui/components/AudioPlayer";
import UserServices from "../services/UserServices";
import { logContentViewed, logCustomEvent } from "../utils/analyticsEvents";
import { useLocation } from "react-router-dom";

const initialState = {
  open: false,
  usingSlot: false,
  audio: {
    url: "",
    image: "",
    title: "",
    subTitle: "",
  },
};

const AudioPlayerContext = createContext(initialState);

export const AudioPlayerProvider = (props) => {
  const { children, user } = props;
  const [state, setState] = useState(initialState);
  const [ubication, setUbication] = useState();
  let titlePlayViewed;
  const location = useLocation();

  useEffect(() => {
    async function getUbication() {
      try {
        const queryParams = new URLSearchParams(location.search);
        const overridedLocation = queryParams.get("override_location");
        const response = await UserServices.getClientLocation(
          overridedLocation
        );
        setUbication(response.location);
      } catch (e) {
        console.log(e);
      }
    }
    getUbication();
  }, []);

  function onSelect(audio) {
    if (false) {
      // TODO: Manage multiple devices
      // const uid = auth.currentUser.uid;
      // const userActiveDevices = database().ref(`devices/${uid}`);
      // userActiveDevices.ref.once("value", async (snapshot) => {
      //   const devicesCount = Object.keys(snapshot?.val() || {})?.length || 0;
      //   if (devicesCount < 5) {
      //     const devicesActive = {};
      //     const regexIp = `${ubication?.ip?.replace(/\./g, "")}`;
      //     devicesActive[regexIp] = devicesCount;
      //     userActiveDevices.set({ ...snapshot?.val(), ...devicesActive });
      //     setState({ audio, open: true, usingSlot: true });
      //     database().ref(`devices/${uid}/${regexIp}`).onDisconnect().remove();
      //   } else {
      //     onToggle("openSlotsFull");
      //   }
      // });
    } else {
      setState({ audio, open: true, usingSlot: true });
    }
  }

  function onClose() {
    if (false) {
      // TODO: Manage multiple devices
      // const uid = auth.currentUser.uid;
      // const userActiveDevices = database().ref(`devices/${uid}`);
      // userActiveDevices.set(devicesConnected);
      // userActiveDevices.onDisconnect().cancel();
    }
    setState({ ...state, open: false, usingSlot: false });
  }

  function onTimeChange(duration, currentTime) {
    const baseParams = {
      contentTitle: state.audio.subTitle,
      contentId: state.audio.id,
    };
    const tribesParams = {
      ...baseParams,
      uid: user.uid,
      created_at: new Date().toISOString(),
      country: ubication.country_code,
    };
    if (
      duration < 1200 &&
      Math.floor(currentTime) === Math.floor(duration * 0.5) &&
      !titlePlayViewed
    ) {
      logCustomEvent({
        name: "title_play_viewed",
        params: baseParams,
      });

      logContentViewed({
        params: tribesParams,
      });

      titlePlayViewed = true;
    } else if (
      duration >= 1200 &&
      Math.floor(currentTime) === 600 &&
      !titlePlayViewed
    ) {
      logCustomEvent({
        name: "title_play_viewed",
        params: baseParams,
      });

      logContentViewed({
        params: tribesParams,
      });

      titlePlayViewed = true;
    }
  }

  return (
    <AudioPlayerContext.Provider value={{ onSelect, onClose }}>
      {children}
      <AudioPlayer
        open={state.open}
        onClose={onClose}
        url={state.audio.url}
        onTimeChange={onTimeChange}
        image={state.audio.image}
        title={state.audio.title}
        color="black"
        subTitle={state.audio.subTitle}
      />
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerContext;

export const useAudioPlayer = () => {
  return useContext(AudioPlayerContext);
};
