import { useState } from "react";
import Modal from "../Modal";
import FormFlow from "./pieces/FormFlow.js";
import PhoneGlitch from "../../../resources/phoneGlitch.svg";
import phoneSelego from "../../../resources/phoneSelego.svg";
import phoneMyst from "../../../resources/phoneMyst.svg";
import PlansFlow from "./pieces/PlansFlow";
import "./styles.css";
import { withLanguage } from "../../../providers/LanguageContext";
import { withContext } from "../../../providers/UserContext";
import Env from "../../../.env.js";
import { loadStripe } from "@stripe/stripe-js";
import { Spinner } from "react-bootstrap";

function FlowSubs(props) {
  const {
    user,
    open,
    onClose,
    labels,
    onSubmit,
    disabled,
    plans,
    currentLanguage,
    colorFlow,
    tycFlow,
    btnFlow,
    bestFlow,
    btnFlowPlans,
    bColor,
    userEmail,
  } = props;

  const [stepChange, setStepChange] = useState(0);

  const [isLoading, setisLoading] = useState();

  const final = (values) => {
    setisLoading(true);
    onSubmit(values)
      .then(() => {
        handleSumit(undefined, values.email);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  const [planSelected, setPlanSelected] = useState(null);
  const stripePromise = loadStripe(Env.stripe_api_key);

  async function handleSumit(plan, email) {
    const myInit = {
      method: "POST",
      body: JSON.stringify({
        priceId: plan ?? planSelected,
        customer_email: email ?? userEmail,
        fromUrl: window.location.origin,
        customer_id: user.stripe_id,
      }),
      headers: {
        "Content-Type": "text/plain",
      },
    };
    console.log("myInit: ", myInit);
    const stripe = await stripePromise;
    try {
      const response = await fetch(
        Env.cloudfunctions_url + "createCheckoutSession",
        myInit
      );
      const session = await response.json();
      stripe.redirectToCheckout({
        sessionId: session.id,
      });
      setisLoading(false);
    } catch (e) {
      console.error("Error on create checkout session : ", e);
      setisLoading(false);
    }
  }

  const componentsStep = [
    <PlansFlow
      bColor={bColor}
      btnFlowPlans={btnFlowPlans}
      bestFlow={bestFlow}
      colorFlow={colorFlow}
      plans={plans}
      labels={labels}
      handleSelectPlan={(plan) => {
        if (user.data.email) {
          setisLoading(true);
          handleSumit(plan);
        } else {
          setPlanSelected(plan);
          setStepChange(stepChange + 1);
        }
      }}
    />,
    <FormFlow
      colorFlow={colorFlow}
      btnFlow={btnFlow}
      stepChange={stepChange}
      final={final}
      language={currentLanguage}
      disabled={disabled}
      labels={labels}
      handleSumit={handleSumit}
    />,
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      parentClassName="hack-master"
      className="flow-subs"
      labels={labels}
    >
      <div className="main-box">
        <div className="second-box">
          <div className="start-free">
            {labels.tittle1}
            {<br></br>}
            {labels.tittle2}
          </div>
          <div className="ms-free">
            {labels.enjoSevenDays}
            {<br></br>}
            {labels.cancelAnyTime}
          </div>
          <div className={`step-at ${colorFlow} `}>
            <span>{labels.step}</span>
            <div>
              <span className={`circle ${Env.env === "myst" && "text-white"}`}>
                {stepChange + 1}
              </span>
            </div>
            <span>{labels.of}</span>
            <div>
              <span className={`circle2 ${Env.env === "myst" && "text-white"}`}>
                3
              </span>
            </div>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center my-8">
              <Spinner animation="border" />
            </div>
          ) : (
            componentsStep[stepChange]
          )}
        </div>
        <div className="phoneImage">
          <img
            src={
              Env.env === "glitch"
                ? PhoneGlitch
                : Env.env === "selego"
                ? phoneSelego
                : phoneMyst
            }
            alt=""
          />
        </div>
      </div>
      <div className="flow-subs-footer">
        <div className="">
          <span className={`tyc ${tycFlow}`}>
            <a
              className="mr-1"
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn3.themyst.com/TermsandConditions.html"
            >
              {labels.termsAndConditions}
            </a>
            {" & "}
            <a
              className="ml-1"
              target="_blank"
              rel="noopener noreferrer"
              href="https://cdn3.themyst.com/privacy.html"
            >
              {labels.privacyPolicy}
            </a>
          </span>
        </div>
        <div className="">
          <span className="media-bv-form">{labels.tribesMediaBV}</span>
          <span className="media-bv">{labels.tribesMediaBV}</span>
        </div>
      </div>
    </Modal>
  );
}

export default withLanguage(withContext(FlowSubs));
