interface EndpointsI {
  getMoviesList: string;
  getMovie: string;

  getSeriesList: string;
  getSerie: string;

  getAudiosList: string;
  getAudio: string;

  getShortsList: string;
  getShort: string;

  getTrendings: string;

  signUp: string;
  signIn: string;
  resetPassword: string;
  sendResetPassword: string;
  confirmEmail: string;
  sendConfirmEmail: string;
  unlockAccount: string;
  sendUnlockEmail: string;
  signOut: string;

  getUser: string;
  availableCountry: string;
}

export const endpointsQuery: EndpointsI = {
  getMoviesList: "/api/v1/schedules?brand_id={brandId}&content_type=Movie",
  getMovie: "/api/v1/schedules/{id}",

  getSeriesList: "/api/v1/schedules?brand_id={brandId}&content_type=Serie",
  getSerie: "/api/v1/schedules/{id}",

  getAudiosList: "/api/v1/schedules?brand_id={brandId}&content_type=Audio",
  getAudio: "/api/v1/schedules/{id}",

  getShortsList: "/api/v1/schedules?brand_id={brandId}&content_type=Short",
  getShort: "/api/v1/schedules/{id}",

  getTrendings: "/api/v1/spotlights?brand_id={brandId}",

  // Auth
  signUp: "/users/tokens/sign_up",
  signIn: "/users/tokens/sign_in",
  sendResetPassword: "/users/password",
  resetPassword: "/users/password?reset_password_token={token}",
  confirmEmail: "/users/confirmation?confirmation_token={token}",
  sendConfirmEmail: "/users/confirmation",
  unlockAccount: "/users/unlock?unlock_token={token}",
  sendUnlockEmail: "/users/unlock",
  signOut: "/users/tokens/revoke",

  // Users
  getUser: "/api/v1/users/1",
  availableCountry: "/api/v1/countries/{countryCode}?brand_id={brandId}",
};
