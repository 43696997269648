import { useEffect, useState } from "react";
import { Logo } from "../../resources/Logos";
import { AuthServices } from "../../services/AuthServices";
import { Link, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import { withLanguage } from "../../providers/LanguageContext";

const UnlockPasswordScreen = (props: any) => {
  const { currentLanguage } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [unlocked, setUnlocked] = useState(false);

  //Get params from url
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const unlockToken = queryParams.get("unlock_token");

  const unlockAccount = async () => {
    if (unlockToken) {
      setIsLoading(true);
      try {
        await AuthServices.unlockPassword(unlockToken);
        setIsLoading(false);
        setUnlocked(true);
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    }
  };

  useEffect(() => {
    unlockAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center items-center flex-col m-5">
      <img src={Logo} alt="Logo" className="d-none d-md-block w-48 m-3" />
      {isLoading ? (
        <Loading show />
      ) : (
        <>
          {unlocked ? (
            <div className="flex flex-col items-center justify-center">
              <span className="text-white mb-4 text-2xl">
                {currentLanguage && currentLanguage === "es"
                  ? "Su Cuenta se ha desbloqueado exitosamente"
                  : "Your Account has been unlocked"}
              </span>
              <button className="primary-background-color text-white font-bold py-2 px-6 mb-4">
                <Link to="/" className="text-white no-underline">
                  {currentLanguage && currentLanguage === "es"
                    ? "Inicio"
                    : "Go home"}
                </Link>
              </button>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <span className="text-white mb-4 text-2xl">{`Error: Token is invalid`}</span>
              <button
                className="primary-background-color text-white font-bold py-2 px-6"
                onClick={unlockAccount}
              >
                {currentLanguage && currentLanguage === "es"
                  ? "Intentar de nuevo"
                  : "Try again"}
              </button>
              <Link to="/" className="text-white no-underline">
                {currentLanguage && currentLanguage === "es"
                  ? "Inicio"
                  : "Go home"}
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withLanguage(UnlockPasswordScreen);
