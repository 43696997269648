import React, { useState, useContext, createContext } from "react";

const initialState = {
  openLogin: false,
  openSignup: false,
  openSlotsFull: false,
  openEditAccount: false,
  openSubscription: false,
  openEditSubscription: false,
  data: {},
};

const ModalsContext = createContext(initialState);

export const ModalsContextProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(initialState);

  function onToggle(key, closeAll = true, data = {}) {
    const base = closeAll ? initialState : state;
    setState({
      ...base,
      data,
      [key]: !state[key],
    });
  }

  return (
    <ModalsContext.Provider value={{ onToggle, ...state }}>
      {children}
    </ModalsContext.Provider>
  );
};

export const ModalsContextConsumer = ModalsContext.Consumer;

export function withModalsContext(Component) {
  return ({ ...props }) => (
    <ModalsContext.Consumer>
      {(propsContext) => <Component {...props} {...propsContext} />}
    </ModalsContext.Consumer>
  );
}

export const useModals = () => {
  return useContext(ModalsContext);
};
