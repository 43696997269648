import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginModal } from "../tribes-media-ui/components";
import { withLanguage } from "../providers/LanguageContext";
import { useModals } from "../providers/ModalsContext";
import { withNotification } from "../providers/NotificationContext";
import UserContext, { withContext } from "../providers/UserContext";
import { Logo } from "./../resources/Logos";
import { logLogin, logScreenView } from "../utils/analyticsEvents";
import { AuthServices } from "../services/AuthServices";

function Login(props) {
  const {
    translate,
    currentLanguage,
    runNotification,
    onClickForgot,
    loadUserData,
  } = props;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const { openLogin, onToggle } = useModals();
  const { setUserAuth } = useContext(UserContext);
  const [emailsSent, setEmailsSent] = useState(undefined);

  async function onLogin(values) {
    setLoading(true);
    try {
      const loginResponse = await AuthServices.signIn(values);
      setUserAuth(loginResponse.data);
      await loadUserData();

      logLogin({
        method: "email",
      });

      setLoading(false);
      onClose();
    } catch (e) {
      logScreenView({
        name: `Login Fail - ${values.email}`,
      });
      if (
        e.response?.data?.error_description &&
        e.response?.data?.error_description.length > 0
      ) {
        setErrorMessage(e.response.data.error_description[0]);
        setLoading(false);
        runNotification(
          { message: e.response.data.error_description[0] },
          "error"
        );
      } else {
        setLoading(false);
        runNotification(e, "error");
      }
    }
  }

  const onClickUnlock = async (email) => {
    try {
      await AuthServices.sendUnlockEmail(email);
      setEmailsSent(true);
      setErrorMessage(undefined);
    } catch (e) {
      runNotification(e, "error");
    }
  };

  const onClickResenConfirmation = async (email) => {
    try {
      await AuthServices.sendConfirmEmail(email);
      setEmailsSent(true);
      setErrorMessage(undefined);
    } catch (e) {
      runNotification(e, "error");
    }
  };

  function onClose() {
    if (history.location?.hash === "#openLogin") {
      history.replace(history.location.pathname);
    }
    onToggle("openLogin");
  }

  function onClickSignup() {
    onToggle("openSignup");
  }

  useEffect(() => {
    if (openLogin) {
      logScreenView({
        name: "Login Screen View",
      });
    }
  }, [openLogin]);

  return (
    <LoginModal
      open={openLogin}
      onClose={onClose}
      title={translate("bannerTitle")}
      loginLabel={translate("login")}
      language={currentLanguage?.toLowerCase()}
      forgotLabel={translate("forgotPassword")}
      signupLabel={translate("tryForFree")}
      onClickSignup={onClickSignup}
      onClickForgot={onClickForgot}
      disabled={loading}
      onSubmit={onLogin}
      color="secondary"
      errorMessage={errorMessage}
      logo={Logo}
      onClickUnlock={onClickUnlock}
      onClickResenConfirmation={onClickResenConfirmation}
      emailsSent={emailsSent}
    />
  );
}

export default withNotification(withLanguage(withContext(Login)));
