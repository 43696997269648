import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "./styles.scss";
import Topbar from "../../components/Topbar/index";
import MainItem from "../../components/MainItem/index";
import BannerSlider from "../../components/BannerSlider";
import BannerSubscription from "../../components/BannerSubscription/index";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import MovieServices from "../../services/MovieServices";
import { withContext } from "../../providers/UserContext";
import { withLanguage } from "../../providers/LanguageContext";
import { routes } from "./../../router";
import ReadMore from "../../components/ReadMore";
import Env from "../../.env.js";
import selegoBannerSub from "../../resources/selegoBannerSub.svg";
import { Transition } from "@tailwindui/react";
import Trending from "./../../components/Trending/index";
import { capitalize } from "@material-ui/core";
import { logScreenView } from "../../utils/analyticsEvents";

class ListMovies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainItem: null,
      lastVisible: null,
      loaded: false,
      movies: [],
      itemHover: null,
    };
  }

  async componentDidMount() {
    if (!this.state.loaded) {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const moviesObject = await MovieServices.getMovies(
      this.state.lastVisible,
      this.props.region
    );
    const oldMovies = this.state.movies;
    const newMovies = moviesObject.movies;
    const movies = oldMovies.concat(newMovies);

    if (!this.state.mainItem) {
      const mainItems = newMovies.slice(0, 7).map((main) => {
        return {
          image: main.data.imageDHigh,
          imageMobile: main.data.imageMHigh,
          imageDetailsMobile: main.data.imageMDetails,
          categoryEN: main.data.tagEN,
          categoryES: main.data.tagES,
          titleES: main.data.titleES,
          contentType: main.data.contentType,
          titleEN: main.data.titleEN,
          descriptionEN: main.data.descriptionEN,
          descriptionES: main.data.descriptionES,
          author: [
            main.data.director1,
            main.data.director2,
            main.data.director3,
          ],
          year: main.data.year,
          id: main.id,
        };
      });

      this.setState({
        mainItem: mainItems,
        movies: movies,
        // .slice(7),
        lastVisible: moviesObject.lastVisible,
        loaded: true,
      });

      logScreenView({
        name: `${capitalize(routes[0])} - Category View`,
      });
    } else {
      this.setState({
        movies: movies,
        lastVisible: moviesObject.lastVisible,
        loaded: true,
      });
    }
  };

  render() {
    const showBanner = !this.props.user || !this.props.user.data?.active;
    return (
      <div className="container-list-movies">
        <Topbar />
        {Env.env === "selego" && (
          <img src={selegoBannerSub} className="bannerSubLeft" />
        )}
        {Env.env === "selego" && (
          <img src={selegoBannerSub} className="bannerSubRight" />
        )}
        {this.state.mainItem !== null && (
          <>
            {this.state.mainItem !== null && (
              <BannerSlider
                items={this.state.mainItem}
                type="list-movies"
                translate={this.props.translate}
                props={this.props}
              />
            )}
            {/* TODO: Display trending items after migrating the backend */}
            <Trending />
            <Container fluid="lg" className="no-padding-responsive">
              {showBanner && (
                <Row className="mt-md-4 no-margin-horizontal-responsive">
                  <Col className="no-padding-responsive">
                    <BannerSubscription category="movies" />
                  </Col>
                </Row>
              )}
              <div className="list-movies">
                <InfiniteScroll
                  dataLength={this.state.movies.length}
                  next={this.fetchData}
                  hasMore={false}
                  // hasMore={this.state.loaded && true}
                  loader={<div></div>}
                >
                  <Grid container>
                    {this.state.movies.length > 0 &&
                      this.state.movies.map((m, k) => {
                        const category =
                          this.props.currentLanguage === "en"
                            ? m.data.tagEN
                            : m.data.tagES;
                        const hasCategory = category && category.length;
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={`list-movie-${m.id}`}
                          >
                            <Link
                              className="text-decoration-none"
                              to={`${routes[0].slice(0, -1)}/${m.id}`}
                            >
                              <div
                                className="relative"
                                onMouseOver={() =>
                                  this.setState({ itemHover: k })
                                }
                                onMouseLeave={() =>
                                  this.setState({ itemHover: null })
                                }
                              >
                                <Paper className="movie">
                                  <img
                                    src={m.data.imageDPeque}
                                    alt="movie list detail"
                                  />
                                  <Col
                                    className={` ${
                                      Env.env === "selego"
                                        ? "overlay-selego"
                                        : "overlay"
                                    }`}
                                  >
                                    <div className="container-data">
                                      <div className="titleMovie">
                                        {this.props.currentLanguage === "en"
                                          ? m.data.titleEN
                                          : m.data.titleES}
                                      </div>
                                      <div className="author">
                                        {m.data.director1}
                                      </div>
                                      <div className="year">{m.data.year}</div>
                                      {Env.env === "selego" &&
                                        hasCategory === 16 && (
                                          <div className="list-category list-category-two">
                                            {category}
                                          </div>
                                        )}
                                      {Env.env === "selego" &&
                                        hasCategory === 17 && (
                                          <div className="list-category list-category-three">
                                            {category}
                                          </div>
                                        )}
                                      {Env.env === "selego" &&
                                        hasCategory === 19 && (
                                          <div className="list-category list-category-four">
                                            {category}
                                          </div>
                                        )}
                                      {Env.env === "selego" &&
                                        hasCategory === 25 && (
                                          <div className="list-category list-category-five">
                                            {category}
                                          </div>
                                        )}
                                      {Env.env === "glitch" && hasCategory && (
                                        <div className="list-category">
                                          {category}
                                        </div>
                                      )}
                                      {Env.env === "myst" && hasCategory && (
                                        <div className="list-category">
                                          {category}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Paper>
                                <Transition
                                  show={this.state.itemHover === k}
                                  enter="transition-opacity duration-300 ease-in-out"
                                  enterFrom="opacity-0"
                                  enterTo="opacity-100"
                                  leave="transition-opacity duration-500 ease-in-out"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Paper
                                    className="movie p-4 m-0 inset-0 transition-opacity"
                                    style={{ position: "absolute" }}
                                  >
                                    <svg
                                      width="40"
                                      height="35"
                                      viewBox="0 0 50 43"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.66249 9.82031H0V35.6303H2.66249V9.82031Z"
                                        fill={Env.colors.primary}
                                      />
                                      <path
                                        d="M42.0248 33.0561H35.2248V25.8365H32.5623V33.0561H14.2443V36.0341C14.2443 37.9293 13.206 39.9731 11.0121 39.9731C8.88208 39.9731 7.55616 37.9452 7.55616 36.0341V8.9236H32.5676V9.82074H35.2301V8.56793V7.59647V1.32713C35.2301 0.594553 34.6337 0 33.8989 0H1.33125C0.596399 0 0 0.594553 0 1.32713V7.15056H2.66249V2.65425H32.5623V6.26935H6.21959C5.48474 6.26935 4.88834 6.8639 4.88834 7.59647V36.0341C4.88834 39.3307 7.28991 42.0752 10.4423 42.6326C10.4476 42.6326 10.4476 42.6326 10.453 42.6379C10.4796 42.6432 10.5009 42.6432 10.5275 42.6485C10.6926 42.6751 10.8523 42.7016 11.0174 42.7122C11.2198 42.7335 11.4168 42.7441 11.6138 42.7441H34.884H35.2887C39.0055 42.7441 42.0195 39.7395 42.0195 36.0341V33.0561H42.0248Z"
                                        fill={Env.colors.primary}
                                      />
                                      <path
                                        d="M25.4157 14.2793H12.918V16.9335H25.4157V14.2793Z"
                                        fill={Env.colors.primary}
                                      />
                                      <path
                                        d="M19.1646 22.291H13.8076V24.9453H19.1646V22.291Z"
                                        fill={Env.colors.primary}
                                      />
                                      <path
                                        d="M40.5123 8.32344L27.4922 18.8164L31.2685 23.4733L44.2886 12.9803L40.5123 8.32344Z"
                                        fill={Env.colors.primary}
                                      />
                                      <path
                                        d="M25.4325 20.502L23.1055 26.2033L29.1866 25.131L25.4325 20.502Z"
                                        fill={Env.colors.primary}
                                      />
                                      <path
                                        d="M48.8084 9.36486C50.1876 8.25007 50.4006 6.22753 49.2824 4.85263L49.2291 4.78893C48.686 4.12006 47.9192 3.70599 47.0618 3.61575C46.2045 3.5255 45.3685 3.775 44.6975 4.31647L43.4355 5.3357L41.9765 6.56728L45.9702 11.5201C46.8808 12.534 46.3057 13.7444 46.0607 14.1531L35.3682 22.599L37.0242 24.6799L47.8925 16.0907C47.9884 16.0164 48.0736 15.9262 48.1481 15.8253C48.9362 14.7265 49.9001 12.1253 48.1109 9.92756L48.8084 9.36486ZM47.1311 7.29985L45.869 8.31909L45.1182 7.39541L46.3802 6.37617C46.508 6.27531 46.6358 6.25408 46.7317 6.25408C46.753 6.25408 46.7743 6.25408 46.7903 6.25938C46.8755 6.27 47.0405 6.30716 47.163 6.46111L47.2109 6.52481C47.408 6.75838 47.3707 7.10875 47.1311 7.29985Z"
                                        fill={Env.colors.primary}
                                      />
                                    </svg>
                                    <div className="title-author mt-3">
                                      {this.props.translate("aboutAuthors")}
                                    </div>
                                    <div className="content-author text-white">
                                      <ReadMore
                                        text={
                                          this.props.currentLanguage === "en"
                                            ? m.data.editorialEN
                                            : m.data.editorialES
                                        }
                                      />
                                    </div>
                                  </Paper>
                                </Transition>
                              </div>
                            </Link>
                          </Grid>
                        );
                      })}
                  </Grid>
                </InfiniteScroll>
              </div>
            </Container>
          </>
        )}
      </div>
    );
  }
}

export default withLanguage(withContext(ListMovies));
