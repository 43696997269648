import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider from "./providers/AuthProvider";
import LanguageProvider from "./providers/LanguageProvider";

import ProtectedPaymentRoute from "./router/ProtectedPaymentRoute";

import ListMovies from "./screens/Movies/listMovies";
import DetailMovie from "./screens/Movies/detailMovie";
import ListSeries from "./screens/Series/listSeries";
import DetailSerie from "./screens/Series/detailSerie";
import ListAudios from "./screens/Audios/listAudios";
import DetailAudio from "./screens/Audios/detailAudio";
import SplashScreen from "./screens/Splash";
import PlayerComponent from "./screens/Player";
import NotFound from "./screens/NotFound/index";
import Unavailable from "./screens/Unavailable/index";
import CancelSubscription from "./screens/CancelSub/index";
import { toast } from "react-toastify";
import AboutUs from "./screens/AboutUs";
import NotificationProvider from "./providers/NotificationProvider";
import CastDetail from "./screens/CastDetail";
import "react-toastify/dist/ReactToastify.css";
import Onboarding from "./components/Onboarding";
import { AudioPlayerProvider } from "./providers/AudioContext";
import { ModalsContextProvider } from "./providers/ModalsContext";
import Analytics from "./components/Analytics";
import Offer from "./components/Offer";
import { routes } from "./router";
import Env from "./.env.js";
import Search from "./screens/Search";
import Helmet from "./components/Helmet";
import PromotionScreen from "./screens/Promotion";
import ConfirmEmailScreen from "./screens/ConfirmEmail";
import UnlockPasswordScreen from "./screens/UnlockPassword";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";

toast.configure();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { localhost: false };
  }

  componentDidMount() {
    const { title, description } = {
      myst: {
        title:
          "MYST. Curated Horror, Thriller, and Suspense Movies & Audiobooks",
        description:
          "MYST. Step into the unknown and let the mystery take over your body and soul: Horror, Crime, Thrillers, and more in handpicked content. Stream Now with a Free Trial",
      },
      glitch: {
        title: "GLITCH. Adult animated movies, short films, and podcasts. 								",
        description:
          "The Revolt of Collective Imagination. Join the 1st adult animation streaming service and support creators with your subscription. #NotForKids								",
      },
      selego: {
        title:
          "Selego. Explore hand-picked independent films, series, audiobooks, podcasts & documentaries.",
        description:
          "Start your FREE trial. Selego is a different kind of streaming service. People curate and select all the movies, series, audiobooks, podcasts & documentaries, not algorithms. Explore independent cinema and open your mind with Selego. ",
      },
    }[Env.env];

    document.title = title;
    document.description = description;

    const favicon = document.getElementById("favicon");
    favicon.href = `/${Env.env}-favicon.ico`;
    this.setState({
      localhost:
        window.location.hostname === "127.0.0.1" ||
        window.location.hostname === "localhost",
    });
  }
  render() {
    return this.state.localhost && !sessionStorage.getItem("env") ? (
      <Router>
        <Switch>
          <Route path="/" exact component={SplashScreen}></Route>
        </Switch>
      </Router>
    ) : (
      <Router>
        <NotificationProvider>
          <AuthProvider>
            <LanguageProvider>
              <ModalsContextProvider>
                <AudioPlayerProvider>
                  <Analytics />
                  <Onboarding />
                  <Offer />

                  <Helmet />

                  <Switch>
                    <Route path="/search" component={Search} />
                    <Route
                      path="/"
                      exact
                      component={Env.env === "glitch" ? ListSeries : ListMovies}
                    />
                    <Route path={`/${routes[0]}`} component={ListMovies} />
                    <Route
                      path={`/${routes[0].slice(0, -1)}/:id`}
                      component={DetailMovie}
                    />
                    <Route path={`/${routes[1]}`} component={ListSeries} />
                    <Route
                      path={`/${routes[1].slice(0, -1)}/:id`}
                      component={DetailSerie}
                    />
                    <Route path="/audios" component={ListAudios} />
                    <Route path="/audio/:id" component={DetailAudio} />
                    <Route path="/about" component={AboutUs} />
                    <Route path="/unavailable" component={Unavailable} />
                    <Route
                      path="/cancel-subscription"
                      component={CancelSubscription}
                    />
                    <Route path="/cast/:name" component={CastDetail} />

                    <Route
                      path="/unlock_account"
                      component={UnlockPasswordScreen}
                    />
                    <Route path="/password" component={ResetPasswordScreen} />

                    <Route
                      path="/confirm_email"
                      component={ConfirmEmailScreen}
                    />
                    <Route path="/promo" component={PromotionScreen} />
                    <ProtectedPaymentRoute
                      path={`/player/${routes[1].slice(0, -1)}/:id`}
                      exact
                      component={PlayerComponent}
                    />
                    <ProtectedPaymentRoute
                      path={`/player/${routes[0].slice(0, -1)}/:id`}
                      exact
                      component={PlayerComponent}
                    />
                    <ProtectedPaymentRoute
                      path="/player/serie/:id/season/:season/episode/:episode"
                      exact
                      component={PlayerComponent}
                    />
                    <ProtectedPaymentRoute
                      path="/player/audio/:id/part/:part/chapter/:chapter"
                      exact
                      component={PlayerComponent}
                    />

                    <Route path="/404" component={NotFound} />
                    <Route component={NotFound} />
                  </Switch>
                </AudioPlayerProvider>
              </ModalsContextProvider>
            </LanguageProvider>
          </AuthProvider>
        </NotificationProvider>
      </Router>
    );
  }
}

export default App;
