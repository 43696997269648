import React, { useState, useEffect, createContext } from "react";
import UserServices from "../services/UserServices";
import { LangProvider } from "./LanguageContext";
import { useLocation } from "react-router-dom";
import LanguageServices from "../services/LanguageServices";

export const LanguageContext = createContext({ region: null });
const languagesList = [
  {
    code: "en",
    flag: "gb",
  },
  {
    code: "es",
    flag: "es",
  },
];

const LanguageProvider = ({ children }) => {
  const [region, setRegion] = useState(undefined);
  const [ip, setIp] = useState(undefined);
  const [languages, setLanguages] = useState(languagesList);
  const [isServiceEnabled, setIsServiceEnabled] = useState(undefined);
  const [countryPlans, setCountryPlans] = useState(undefined);
  const [realRegion, setRealRegion] = useState(undefined);

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const overridedLocation = queryParams.get("override_location");
        const response = await UserServices.getClientLocation(
          overridedLocation
        );

        if (response?.countryPlans?.default) {
          setCountryPlans(
            Object.keys(response.countryPlans?.default).map((k) => ({
              ...response.countryPlans?.default[k],
              interval: k,
            }))
          );
        }
        setIsServiceEnabled(response.isServiceEnabled);

        const languagesData = await LanguageServices.getLanguagesData();

        // Get params from URL
        if (!region) {
          setRegion(overridedLocation ?? response?.country_code ?? "NL");
          setIp(response.ip);
          if (languagesData) {
            setLanguages(languagesData);
          }
          setIsServiceEnabled(response.isServiceEnabled);
          setRealRegion(response.country_code ?? "NL");
        }
      } catch (e) {
        console.log("e", e);
        console.log(e);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <LangProvider
      value={{
        region,
        ip,
        languages,
        isServiceEnabled,
        realRegion,
        countryPlans,
      }}
    >
      {children}
    </LangProvider>
  );
};

export default LanguageProvider;
