import React from "react";
import "./styles.scss";
import { Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { withLanguage } from "../../providers/LanguageContext";
import useWindowSize from "../../hooks/useWindowSize";
import { withContext } from "../../providers/UserContext";
import { useModals } from "../../providers/ModalsContext";
import { useAudioPlayer } from "../../providers/AudioContext";
import Env from "./../../.env.js";

function MainItem(props) {
  const {
    item,
    currentLanguage,
    region,
    user,
    translate,
    type,
    isServiceEnabled,
  } = props;
  const history = useHistory();
  const { onClose } = useAudioPlayer(user);
  const [width, height] = useWindowSize();
  const { onToggle } = useModals();
  const glitch = Env.env === "glitch";

  const category = currentLanguage === "en" ? item.categoryEN : item.categoryES;

  function getLink() {
    if (region !== "XY" && !isServiceEnabled) return "/unavailable";
    switch (type) {
      case "detail-serie": {
        return glitch
          ? `/player/short/${item.id}`
          : `/player/serie/${item.id}/season/${item?.seasons?.[0]?.number}/episode/${item?.seasons?.[0].episodes?.[0]?.number}`;
      }
      case "detail-audio": {
        return `/player/audio/${item.id}/part/${item?.parts?.[0]?.number}/chapter/${item.parts?.[0]?.chapters?.[0].number}`;
      }
      default:
        return `/player/movie/${item.id}`;
    }
  }
  function getBgImage() {
    if (width < 990) {
      return type.includes("list") ? item.imageMobile : item.imageDetailsMobile;
    } else {
      return item.image;
    }
  }
  async function onPlay() {
    if (user?.data?.active) {
      onClose();
      const link = getLink();

      history.push(link);
    } else if (!user.auth) {
      onToggle("openLogin");
    } else {
      onToggle("openSignup", true, {
        contentType: item.contentType,
        contentTitle: item.titleEN,
      });
    }
  }
  const bgImage = getBgImage();
  const backgroundImage = `url(${bgImage})`;
  const hasCategory = category && category.length > 0;
  return (
    <div
      className={`main-item ${Env.env === "selego" && "main-selego"}`}
      style={{ backgroundImage, height: height / 1.5 }}
    >
      <div className={` ${Env.env === "selego" ? "" : "overlay"}`}>
        <Container fluid="lg" className="no-padding-large">
          <Row>
            <Col>
              <div className="title-movie">
                {currentLanguage === "en" ? item.titleEN : item.titleES}
              </div>
              {item.author &&
                item.author.map((a, aIndex) => (
                  <div className="author" key={`author-${aIndex}`}>
                    {a}
                  </div>
                ))}
              {/* { type === 'list-series' && (
								<div className="author">
									{ item.seasons.length } SEASONS
								</div>
							)} */}
              {(type === "list-movies" ||
                type === "list-series" ||
                type === "list-audios") && (
                <div className="year list-year">{item.year}</div>
              )}
              {type === "detail-movie" && !glitch && (
                <div className="year detail-year">{item.year}</div>
              )}
              {hasCategory && <div className="category">{category}</div>}
              {/* { type === 'detail-serie' && (
								<div className="year detail-year">
									{ item.year } { item.seasons.length } SEASONS
								</div>
							)} */}
              {(type === "detail-movie" ||
                type === "detail-serie" ||
                type === "detail-audio") && (
                <div className="resume">
                  {currentLanguage === "en" ? item.resumeEN : item.resumeES}
                </div>
              )}
              {(type === "detail-movie" || type === "detail-serie") && (
                <div className="extra-container">
                  {isServiceEnabled && (
                    <button
                      onClick={onPlay}
                      className={`play-button ${glitch && "black-color"}`}
                    >
                      <div className="play">
                        <svg
                          width="2em"
                          height="2em"
                          viewBox="0 0 16 16"
                          className="bi bi-play-fill"
                          fill={glitch ? "#000" : "#fff"}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                        </svg>
                      </div>
                      <div className="label">{translate("play")}</div>
                    </button>
                  )}
                  <div className="extra">
                    <div className="quality">
                      <svg
                        width="22"
                        height="18"
                        viewBox="0 0 22 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.9393 17.6389H19.5748C20.3625 17.6389 21.0035 16.9979 21.0035 16.2102V2.29977C21.0035 1.51214 20.3625 0.871094 19.5748 0.871094H1.42868C0.641045 0.871094 0 1.51214 0 2.29977V16.2124C0 17 0.641045 17.6411 1.42868 17.6411H10.0642H10.9393V17.6389ZM20.1284 16.2124C20.1284 16.5165 19.8811 16.7659 19.5748 16.7659H1.42868C1.12238 16.7638 0.875147 16.5165 0.875147 16.2124V2.29977C0.875147 1.99347 1.12238 1.74624 1.42868 1.74624H19.577C19.8811 1.74624 20.1284 1.99347 20.1284 2.29977V16.2124Z"
                          fill="white"
                        />
                        <path
                          d="M6.48933 11.5835C6.73 11.5835 6.9269 11.3866 6.9269 11.1459V9.6932H9.12571V11.1459C9.12571 11.3866 9.32262 11.5835 9.56328 11.5835C9.80395 11.5835 10.0009 11.3866 10.0009 11.1459V7.36531C10.0009 7.12464 9.80395 6.92773 9.56328 6.92773C9.32262 6.92773 9.12571 7.12464 9.12571 7.36531V8.81805H6.9269V7.36531C6.9269 7.12464 6.73 6.92773 6.48933 6.92773C6.24867 6.92773 6.05176 7.12464 6.05176 7.36531V11.1459C6.05176 11.3888 6.24867 11.5835 6.48933 11.5835Z"
                          fill="white"
                        />
                        <path
                          d="M11.4425 11.5835H12.6655C13.9257 11.5835 14.9518 10.5377 14.9518 9.25125C14.9518 7.96916 13.9257 6.92773 12.6655 6.92773H11.4425C11.2018 6.92773 11.0049 7.12464 11.0049 7.36531V11.1459C11.0049 11.3888 11.2018 11.5835 11.4425 11.5835ZM11.88 7.80288H12.6655C13.4422 7.80288 14.0766 8.45268 14.0766 9.25125C14.0766 10.0542 13.4444 10.7084 12.6655 10.7084H11.88V7.80288Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="mins">
                      <svg
                        width="17"
                        height="18"
                        viewBox="0 0 17 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 0.871094C3.81331 0.871094 0 4.68441 0 9.37109C0 14.0578 3.81331 17.8711 8.5 17.8711C13.1867 17.8711 17 14.0578 17 9.37109C17 4.68441 13.1867 0.871094 8.5 0.871094ZM8.5 16.9536C4.32058 16.9536 0.917489 13.5527 0.917489 9.37109C0.917489 5.18947 4.31837 1.79079 8.5 1.79079C12.6816 1.79079 16.0825 5.19167 16.0825 9.37109C16.0825 13.5505 12.6794 16.9536 8.5 16.9536Z"
                          fill="white"
                        />
                        <path
                          d="M13.2267 9.35051H8.87306V4.92628C8.87306 4.52929 8.54885 4.20508 8.15186 4.20508C7.75266 4.20508 7.43066 4.52929 7.43066 4.92628V10.0717C7.43066 10.4709 7.75487 10.7929 8.15186 10.7929H13.2267C13.6259 10.7929 13.9479 10.4687 13.9479 10.0717C13.9479 9.67472 13.6237 9.35051 13.2267 9.35051Z"
                          fill="white"
                        />
                      </svg>
                      <span>{item.minutes}'</span>
                    </div>
                    <div className="audio">
                      <svg
                        width="12"
                        height="18"
                        viewBox="0 0 12 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5819 0.978967C11.4567 0.908519 11.3159 0.871094 11.1705 0.871094C11.0208 0.871094 10.8777 0.910721 10.7548 0.98337L10.2853 1.24535L5.13483 4.52779C4.918 4.66648 4.85764 4.95048 4.99624 5.16402C5.08342 5.29611 5.22872 5.37537 5.38744 5.37537C5.47686 5.37537 5.56404 5.34895 5.64004 5.30052L10.7525 2.04009L11.0677 1.86397V16.8716L10.7592 16.7043L3.93216 12.3519C3.91875 12.3431 3.90533 12.3365 3.88074 12.3255L3.85616 12.3145C3.8338 12.3057 3.81144 12.2991 3.79133 12.2947L3.75556 12.3982L3.76897 12.2881C3.74662 12.2837 3.72203 12.2815 3.70861 12.2815C3.70414 12.2815 3.70191 12.2815 3.69967 12.2815L3.66614 12.2793H1.35468C1.1222 12.2793 0.934418 12.0944 0.934418 11.8654V6.87679C0.934418 6.64784 1.1222 6.46291 1.35468 6.46291H3.21011V10.9055C3.21011 11.1587 3.42024 11.3657 3.67732 11.3657C3.93439 11.3657 4.14453 11.1587 4.14453 10.9055V6.0028C4.14453 5.74962 3.93663 5.54488 3.67285 5.54488H1.35468C0.608043 5.54488 0 6.14369 0 6.87899V11.8676C0 12.6029 0.608043 13.2017 1.35468 13.2017H3.54096L10.2831 17.499L10.7391 17.7522C10.8688 17.8315 11.0185 17.8711 11.1728 17.8711C11.3159 17.8711 11.4545 17.8359 11.5774 17.7676C11.839 17.6223 11.9999 17.3515 11.9999 17.0565V1.69005C12.0044 1.39065 11.8457 1.12427 11.5819 0.978967Z"
                          fill="white"
                        />
                      </svg>
                      <span>{item.audio}</span>
                    </div>
                    <div className="cc">
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.5737 0.871094H1.42844C0.640937 0.871094 0 1.51203 0 2.29953V16.2098C0 16.9973 0.640937 17.6383 1.42844 17.6383H10.0625H10.9375H19.5716C20.3591 17.6383 21 16.9973 21 16.2098V2.29953C21.0022 1.51203 20.3612 0.871094 19.5737 0.871094ZM20.1272 16.2098C20.1272 16.5139 19.88 16.7633 19.5737 16.7633H1.42844C1.12437 16.7633 0.875 16.5161 0.875 16.2098V2.29953C0.875 1.99547 1.12219 1.74609 1.42844 1.74609H19.5737C19.8778 1.74609 20.1272 1.99328 20.1272 2.29953V16.2098Z"
                          fill="white"
                        />
                        <path
                          d="M9.49586 10.2683C9.38648 10.2683 9.28804 10.312 9.21367 10.3733C8.94461 10.5877 8.67992 10.7102 8.2818 10.7102C7.5468 10.7102 7.0393 10.0977 7.0393 9.36266V9.34953C7.0393 8.61453 7.55992 8.01516 8.2818 8.01516C8.61867 8.01516 8.8943 8.12453 9.1568 8.32141C9.22461 8.36516 9.30992 8.41328 9.43898 8.41328C9.69711 8.41328 9.90492 8.21203 9.90492 7.95391C9.90492 7.78766 9.81961 7.65422 9.72117 7.57984C9.3668 7.31734 8.93148 7.14453 8.28836 7.14453C6.97148 7.14453 6.05273 8.14203 6.05273 9.36266V9.37578C6.05273 10.6073 6.98898 11.5808 8.25117 11.5808C8.93805 11.5808 9.39086 11.3795 9.77586 11.0427C9.86117 10.9683 9.93555 10.8523 9.93555 10.7058C9.93773 10.4652 9.7343 10.2683 9.49586 10.2683Z"
                          fill="white"
                        />
                        <path
                          d="M14.5076 10.2683C14.3982 10.2683 14.2998 10.312 14.2254 10.3733C13.9563 10.5877 13.6916 10.7102 13.2935 10.7102C12.5585 10.7102 12.051 10.0977 12.051 9.36266V9.34953C12.051 8.61453 12.5716 8.01516 13.2935 8.01516C13.6304 8.01516 13.906 8.12453 14.1685 8.32141C14.2363 8.36516 14.3216 8.41328 14.4507 8.41328C14.7088 8.41328 14.9166 8.21203 14.9166 7.95391C14.9166 7.78766 14.8313 7.65422 14.7329 7.57984C14.3785 7.31734 13.9432 7.14453 13.3001 7.14453C11.9832 7.14453 11.0645 8.14203 11.0645 9.36266V9.37578C11.0645 10.6073 12.0007 11.5808 13.2629 11.5808C13.9498 11.5808 14.4026 11.3795 14.7876 11.0427C14.8729 10.9683 14.9473 10.8523 14.9473 10.7058C14.9495 10.4652 14.7482 10.2683 14.5076 10.2683Z"
                          fill="white"
                        />
                      </svg>

                      {item.cc.length > 0 && item.cc.join(", ")}
                    </div>
                  </div>
                </div>
              )}
              {type === "detail-audio" && (
                <div className="extra">
                  <div className="audio">
                    <svg
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 16 16"
                      className="bi bi-volume-down-fill"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"
                      />
                      <path d="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z" />
                    </svg>
                    {item.audio}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default withContext(withLanguage(MainItem));
