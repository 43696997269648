import { auth, firestore, firebaseInstance } from "./FirebaseService";

import { AxiosError, AxiosResponse } from "axios";

import Env from "../.env";
import { log } from "../utils/functions";
import { genericGet, genericPatch } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";
import { IEditUserParams, IUser } from "../models/interfaces/UserInterface";
import { AuthServices } from "./AuthServices";

const UserServices = {
  getClientLocation: async (overridedRegion?: string) => {
    try {
      const request = await fetch(
        "https://api.ipapi.com/api/check?access_key=168ce034987bb53eb8ef5b77c8e086f7"
      );
      if (overridedRegion === "null") {
        localStorage.removeItem("overridedRegion");
      }

      const location = await request.json();

      const storagedRegion =
        overridedRegion ?? localStorage.getItem("overridedRegion");

      const response =
        storagedRegion === null || storagedRegion === "null"
          ? await AuthServices.getCountryPlans(location.country_code)
          : { enabled: false, plans: [] };

      console.log("response", response);
      if (overridedRegion && overridedRegion !== "null") {
        localStorage.setItem("overridedRegion", overridedRegion);
      }

      return {
        ...location,
        country_code: location.country_code,
        isServiceEnabled: response.enabled,
        countryPlans: response.plans,
      };
    } catch (e: any) {
      log("Error @IPAPI", "error");
      log(e, "error");

      return {
        ip: "127.0.0.1",
        country_code: "XY",
      };
    }
  },  
  cancelPlan: (stripe_id: string, cancelFormData: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!stripe_id) {
          return resolve({ message: "No stripe id" });
        }

        if (stripe_id) {
          fetch(Env.cloudfunctions_url + "cancelSubscription", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              stripe_id,
            }),
          })
            .then((response) => response.json())
            .then(async (res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } catch (error: any) {
        console.log("=====", error);
        reject(error.message);
      }
    });
  },
  log: (file: any, data: any) => {
    return new Promise<void>(async (resolve, reject) => {
      const currentTime =
        firebaseInstance.firestore.FieldValue.serverTimestamp();
      const user = auth.currentUser as any;
      const docRefCollection = firestore
        .collection("user_log")
        .where("uid", "==", user.uid)
        .where("file", "==", file)
        .limit(1);
      docRefCollection.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          firestore.collection("user_log").add({
            uid: user.uid,
            file: file,
            data: data,
            created_at: currentTime,
            updated_at: currentTime,
          });
        } else {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              uid: user.uid,
              file: file,
              data: data,
              created_at: currentTime,
              updated_at: currentTime,
            });
          });
        }
      });
      resolve();
    });
  },
  getLog: (file: any) => {
    // return new Promise(async (resolve, reject) => {
    //   const user = auth.currentUser as any;
    //   const docRefCollection = firestore
    //     .collection("user_log")
    //     .where("uid", "==", user.uid)
    //     .where("file", "==", file)
    //     .limit(1);
    //   docRefCollection.get().then((querySnapshot) => {
    //     if (querySnapshot.empty) {
    //       resolve(null);
    //     } else {
    //       querySnapshot.forEach((doc) => {
    //         resolve(doc.data());
    //       });
    //     }
    //   });
    // });
    // TODO: Get movie time from last seen
    return new Promise((resolve) => resolve(null));
  },
  getUser: async (token: string): Promise<AxiosResponse<IUser, AxiosError>> => {
    const response = await genericGet(
      endpointsQuery.getUser,
      undefined,
      undefined,
      token
    );
    return response.data;
  },
  editUser: async (
    params: IEditUserParams,
    token: string
  ): Promise<AxiosResponse<{ message: string; user: IUser }, AxiosError>> => {
    console.log("params", params);
    let user: Record<string, any> = {};
    if (params?.lastName) {
      user["last_name"] = params.lastName;
    }
    if (params?.name) {
      user["first_name"] = params.name;
    }
    if (params?.language) {
      user["language"] = params.language;
    }

    const response = await genericPatch(
      endpointsQuery.getUser.replace("{id}", params.userId.toString()),
      undefined,
      {
        user,
      },
      token
    );
    return response;
  },
};

export default UserServices;
