import React, { useContext, useEffect } from "react";
import FlowSubs from "../tribes-media-ui/components/FlowSubs";
import UserContext, { withContext } from "../providers/UserContext";
import { withModalsContext } from "../providers/ModalsContext";
import { withNotification } from "../providers/NotificationContext";
import { withLanguage } from "../providers/LanguageContext";
import { useHistory } from "react-router-dom";
import { useModals } from "../providers/ModalsContext";
import { logScreenView, logSignUp } from "../utils/analyticsEvents";
import { AuthServices } from "../services/AuthServices";

function FlowSubscription(props) {
  const { translate, currentLanguage, runNotification, user, countryPlans } =
    props;

  const { setUserAuth } = useContext(UserContext);

  function onSignup(values) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await AuthServices.signUp({
          email: values.email,
          password: values.password,
          language: currentLanguage,
        });
        setUserAuth(response.data);

        logSignUp({
          method: "email",
        });

        resolve();
      } catch (e) {
        const error = e.response.data.error_description[0] ?? e;
        runNotification(error, "error on signup");
        reject();
      }
    });
  }
  const { openSignup, onToggle } = useModals();

  const history = useHistory();

  useEffect(() => {
    if (openSignup && countryPlans !== undefined && countryPlans?.length === 0)
      history.push("/unavailable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryPlans, openSignup]);

  useEffect(() => {
    if (openSignup) {
      logScreenView({
        name: "Begin Account Creation View",
      });
    }
  }, [openSignup]);

  const plansList = countryPlans?.map((plan) => ({
    ...plan,
    label: plan[currentLanguage?.toLowerCase()],
    discountLabel: plan[`subtitle_${currentLanguage?.toLowerCase()}`],
    id: plan.product_id_test,
    hasCoupon: plan.interval === "yearly",
    interval: plan.interval,
  }));

  if (countryPlans && countryPlans.length) {
    return (
      <FlowSubs
        onSubmit={onSignup}
        userEmail={user?.data?.email ?? ""}
        plans={plansList}
        open={openSignup}
        labels={{
          tittle1: translate("startSubscriptionPart1"),
          tittle2: translate("startSubscriptionPart2"),
          or: translate("or"),
          enjoSevenDays: translate("enjoSevenDays"),
          cancelAnyTime: translate("cancelAnyTime"),
          step: translate("step"),
          of: translate("of"),
          createAccount: translate("createAccount"),
          email: translate("email"),
          password: translate("password"),
          repeatPassword: translate("passwordFlow"),
          confirm: translate("confirmBtn"),
          termsAndConditions: translate("termsAndUse"),
          privacyPolicy: translate("privacyPolicy"),
          tribesMediaBV: translate("tribesMediaBV"),
          choosePlan: translate("choosePlan"),
          close: translate("close"),
          bestOffer: translate("bestOffer"),
          saveOneYear1: translate("saveOneYearPart1"),
          saveOneYear2: translate("saveOneYearPart2"),
          join: translate("pricingJoin"),
        }}
        onClose={() => {
          onToggle("openSignup");
        }}
        colorFlow="color-glitch"
        tycFlow="tyc-glitch"
        btnFlow="btn-confirm-glitch"
        bestFlow="best-offer-glitch"
        bColor="b-glitch"
      />
    );
  }
  return <React.Fragment />;
}

export default withNotification(
  withModalsContext(withLanguage(withContext(FlowSubscription)))
);
