import * as React from "react";
import { Row, Col } from "react-bootstrap";
import Topbar from "../../components/Topbar/index";
import { withLanguage } from "../../providers/LanguageContext";
import { withContext } from "../../providers/UserContext";
import { Logo } from "./../../resources/Logos";
import "./styles.scss";
import { AuthServices } from "../../services/AuthServices";

function UnavailableScreen(props) {
  const { translate, history, region } = props;

  React.useEffect(() => {
    if (region && !AuthServices.getCountryPlans(region)) {
      history.push("/login");
    }
  }, [region, history]);

  return (
    <div className="container-unavailable">
      <Topbar />
      <div className="unavailable">
        <Row className="logo">
          <Col>
            <img src={Logo} alt="Logo" className="d-none d-md-block w-50" />
          </Col>
        </Row>
        <Row className="text-1">
          <Col>
            <span>{translate("bannerTitle")}</span>
          </Col>
        </Row>
        <Row className="text-2">
          <Col>
            <p>{translate("subNotAvailable")}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withContext(withLanguage(UnavailableScreen));
