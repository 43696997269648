import React, { useEffect, useState } from "react";

import promoImageGlitch from "../../resources/Promos/GlitchPromotion.png";
import PromoImageGlitchMobile from "../../resources/Promos/GlitchPromotionMobile.png";
import promoImageSelego from "../../resources/Promos/SelegoPromotion.png";
import promoImageSelegoMobile from "../../resources/Promos/SelegoPromotionMobile.png";

import promoImageMyst from "../../resources/Promos/MystPromotion.png";
import promoImageMystMobile from "../../resources/Promos/MystPromotionMobile.png";

import GlitchLogo from "../../resources/Logos/glitch-logo.svg";
import MystLogo from "../../resources/Logos/myst-logo.svg";
import SelegoLogo from "../../resources/Logos/selego-logo.svg";

import { loadStripe } from "@stripe/stripe-js";
import Env from "../../.env.js";
import FormFlow from "../../tribes-media-ui/components/FlowSubs/pieces/FormFlow.js";
import { withLanguage } from "../../providers/LanguageContext.js";
import { withNotification } from "../../providers/NotificationContext.js";
import { withContext } from "../../providers/UserContext.js";
import { withModalsContext } from "../../providers/ModalsContext.js";
import "tailwindcss/tailwind.css";
import CountdownTimer from "../../components/CountDownTimer";
import { AuthServices } from "../../services/AuthServices";

type BrandKey = "selego" | "myst" | "glitch";

type BrandsData = {
  logo: any;
  backgroundImage: any;
  backgroundMobile: any;
  backgroundColor: string;
  colorTitle: string;
  colorDescription: string;
  colorBackgroundButton: string;
  colorTextButton: string;
};

const brands_data: Record<BrandKey, BrandsData> = {
  selego: {
    logo: SelegoLogo,
    backgroundImage: promoImageSelego,
    backgroundMobile: promoImageSelegoMobile,
    backgroundColor: "bg-white",
    colorTitle: "#00574E",
    colorDescription: "#000",
    colorBackgroundButton: "#00574E",
    colorTextButton: "#fff",
  },
  myst: {
    logo: MystLogo,
    backgroundImage: promoImageMyst,
    backgroundMobile: promoImageMystMobile,
    backgroundColor: "bg-white",
    colorTitle: "#fff",
    colorDescription: "#fff",
    colorBackgroundButton: "#5E01F6",
    colorTextButton: "#fff",
  },
  glitch: {
    logo: GlitchLogo,
    backgroundImage: promoImageGlitch,
    backgroundMobile: PromoImageGlitchMobile,
    backgroundColor: "bg-black",
    colorTitle: "#FFFF00",
    colorDescription: "#fff",
    colorBackgroundButton: "#FFFF00",
    colorTextButton: "#000",
  },
};

const brand = brands_data[Env.env as BrandKey];
const PromotionScreen = (props: any) => {
  const [planSelected, setPlanSelected] = useState<string | null>(null);
  const [promoData, setPromo] = useState<any | undefined>();
  const [promoCoupon, setPromoCoupon] = useState<string | undefined>();

  const [step, setStep] = useState(0);
  const [promoExpiration, setPromoExpiration] = useState<Date | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const { runNotification, translate, currentLanguage } = props;

  const getRegionPlans = () => {
    try {
      const plans = props?.countryPlans || [];

      if (plans.length > 0) {
        // const mensualPlan = plans.find((p: any) => p.interval === "monthly");
        const yearlyPlan = plans.find((p: any) => p.interval === "yearly");
        const promoPlan = plans.find((p: any) => p.interval === "promo");

        setPromo(promoPlan[currentLanguage]);

        getPromotionExpiration(promoPlan?.expires_at);
        setPromoCoupon(promoPlan?.id);
        setPlanSelected(yearlyPlan?.product_id);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };

  const getPromotionExpiration = async (expirationDate: Date) => {
    try {
      if (expirationDate) {
        setPromoExpiration(expirationDate);
      }
    } catch (error) {
      console.log("error to get promotion expiration date", error);
    }
  };

  const stripePromise = loadStripe(Env.stripe_api_key);

  useEffect(() => {
    getRegionPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    setIsLoading(true);
    onSignup(values)
      .then(async (customer_email) => {
        await goToStripe(customer_email);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  function onSignup(values: any) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        await AuthServices.signUp({
          email: values.email,
          password: values.password,
        });

        resolve(values.email);
      } catch (e: any) {
        const error = e.response.data.error_description[0] ?? e;
        runNotification(error, "error");
        reject();
      }
    });
  }

  const goToStripe = async (customer_email: string) => {
    const myInit = {
      method: "POST",
      body: JSON.stringify({
        priceId: planSelected,
        coupon: promoCoupon || "",
        customer_email,
        withoutTrial: true,
      }),
      headers: {
        "Content-Type": "text/plain",
      },
    };

    const stripe = await stripePromise;
    try {
      const response = await fetch(
        Env.cloudfunctions_url + "createCheckoutSession",
        myInit
      );
      const session = await response.json();
      if (stripe !== null) {
        stripe.redirectToCheckout({
          sessionId: session.id,
        });
      }
      setIsLoading(false);
    } catch (e) {
      console.log("-- error -- : ", e);
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* /MOBILE */}

      <div
        className="position-relative text-white hidden lg:block"
        style={{ height: "100vh" }}
      >
        <div className="flex overflow-x-hidden absolute top-1 w-full z-20">
          <div className="py-12 animate-marquee whitespace-nowrap ">
            <span
              className="text-5xl font-bold"
              style={{
                color: "transparent",
                WebkitTextStroke: `1px ${brand?.colorTitle}`,
                textShadow: "none",
              }}
            >
              {promoData?.banner}
            </span>
          </div>

          <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap ml-8 z-20">
            <span
              className="text-5xl font-bold"
              style={{
                color: "transparent",
                WebkitTextStroke: `1px ${brand?.colorTitle}`,
                textShadow: "none",
              }}
            >
              {promoData?.banner}
            </span>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center h-full justify-center position-absolute z-20"
          style={{ width: "75%" }}
        >
          <img
            src={brand?.logo}
            alt={`${Env.env} Logo`}
            style={{
              height: window.innerHeight * 0.2,
              width: window.innerWidth * 0.25,
            }}
          />
          {step === 0 ? (
            <>
              <p
                className="fw-700 fs-24 my-3 text-6xl text-center"
                style={{
                  color: `${brand?.colorTitle}`,
                  width: "75%",
                  marginBottom: "-2px !important",
                }}
              >
                {promoData?.title}
              </p>
              <p
                className="fw-700 text-4xl text-center"
                style={{
                  color: `${brand?.colorTitle}`,
                  width: "75%",
                }}
              >
                {promoData?.subtitle}
              </p>
              <p
                className="fs-18 my-6 text-xl text-center fw-600"
                style={{ width: "60%", color: `${brand?.colorDescription}` }}
              >
                {promoData?.body}
              </p>

              <div className="text-white text-center ">
                <div className="text-6xl font-thin relative flex">
                  <span
                    className="text-5xl fw-700"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                      color: `${brand?.colorDescription}`,
                    }}
                  >
                    {promoData?.discounted_price}
                  </span>
                  <span
                    className="text-5xl  rotate-12"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                      color: `${brand?.colorDescription}`,
                    }}
                  >
                    /
                  </span>
                  <span
                    className="text-3xl fw-700"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      color: `${brand?.colorDescription}`,
                    }}
                  >
                    {promoData?.period}
                  </span>
                </div>
                <div
                  className="text-3xl line-through my-2"
                  style={{ color: `${brand?.colorDescription}` }}
                >
                  {promoData?.full_price}
                </div>
              </div>
              {promoExpiration && (
                <CountdownTimer
                  targetDate={promoExpiration.toString()}
                  translate={translate}
                  timerColor={brand?.colorTitle}
                  textColor={brand?.colorDescription}
                >
                  <span
                    className="text-3xl fw-700 uppercase mt-4 px-6 py-3 cursor-pointer"
                    style={{
                      backgroundColor: brand?.colorBackgroundButton,
                      color: brand?.colorTextButton,
                    }}
                    onClick={() => setStep(1)}
                  >
                    {promoData?.call_to_action}
                  </span>
                </CountdownTimer>
              )}
            </>
          ) : (
            <FormFlow
              colorFlow="color-glitch"
              tycFlow="tyc-glitch"
              btnFlow="btn-confirm-glitch"
              bestFlow="best-offer-glitch"
              bColor="b-glitch"
              stepChange={() => {}}
              final={onFinish}
              language={"ES"}
              disabled={isLoading}
              labels={{
                createAccount: translate("createAccount"),
                email: translate("email"),
                password: translate("password"),
                repeatPassword: translate("passwordFlow"),
                confirm: translate("confirmBtn"),
                join: translate("pricingJoin"),
              }}
            />
          )}
        </div>

        <img
          src={brand?.backgroundImage}
          alt="Promotion"
          className="position-absolute z-0"
          style={{
            right: 0,
            bottom: 0,
            height: "100%",
            objectFit: "cover",
            zIndex: 0,
          }}
        />
      </div>
      {/* /END DESKTOP */}

      {/* /MOBILE */}
      <div
        className="position-relative bg-black text-white lg:hidden"
        style={{ height: "100vh" }}
      >
        {step === 0 && (
          <>
            <div className="absolute left-0 top-0 h-full w-1/6 overflow-hidden z-20">
              <div className="animate-marqueeV h-full flex flex-col justify-center items-center">
                <div className="py-12 whitespace-nowrap mr-6 z-20">
                  <span
                    className="text-2xl font-bold block"
                    style={{
                      color: "transparent",
                      WebkitTextStroke: `1px ${brand?.colorTitle}`,
                      textShadow: "none",
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                    }}
                  >
                    {promoData?.banner}
                  </span>
                </div>
              </div>

              <div className="animate-marqueeV2 h-full flex flex-col justify-center items-center">
                <div className="py-12 whitespace-nowrap mr-6 z-20">
                  <span
                    className="text-2xl font-bold block"
                    style={{
                      color: "transparent",
                      WebkitTextStroke: `1px ${brand?.colorTitle}`,
                      textShadow: "none",
                      writingMode: "vertical-rl",
                      transform: "rotate(180deg)",
                    }}
                  >
                    {promoData?.banner}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="d-flex flex-column justify-content-center align-items-center h-full justify-center position-absolute z-10"
          style={{ width: "100%" }}
        >
          <img
            src={brand?.logo}
            alt={`${Env.env} Logo`}
            height={200}
            width={200}
          />
          {step === 0 ? (
            <>
              <p
                className="fw-700 mt-8 text-xl text-center z-0"
                style={{
                  color: `${brand?.colorTitle}`,
                  width: "60%",
                  marginBottom: "-2px !important",
                  zIndex: 0,
                }}
              >
                {promoData?.title}
              </p>
              <p
                className="fw-700 text-xl mb-2 text-center"
                style={{
                  color: `${brand?.colorTitle}`,
                  width: "60%",
                }}
              >
                {promoData?.subtitle}
              </p>
              <p
                className="fs-18 mb-2 text-sm text-center"
                style={{ width: "70%", color: `${brand?.colorDescription}` }}
              >
                {promoData?.body}
              </p>

              <div className="text-white text-center mt-1 mb-1 md:mt-0 md:mb-0">
                <div className="text-3xl font-bold relative flex">
                  <span
                    className="text-2xl"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                      color: `${brand?.colorDescription}`,
                    }}
                  >
                    {promoData?.discounted_price}
                  </span>
                  <span
                    className="text-2xl  rotate-12"
                    style={{
                      alignItems: "end",
                      display: "flex",
                      marginBottom: 2,
                      color: `${brand?.colorDescription}`,
                    }}
                  >
                    /
                  </span>
                  <span
                    className="text-lg mt-1 ml-1 bold"
                    style={{ color: `${brand?.colorDescription}` }}
                  >
                    {promoData?.period}
                  </span>
                </div>
                <div
                  className="text-lg line-through mb-1"
                  style={{ color: `${brand?.colorDescription}` }}
                >
                  {promoData?.full_price}
                </div>
              </div>

              {promoExpiration && (
                <CountdownTimer
                  targetDate={promoExpiration.toString()}
                  translate={translate}
                  timerColor={brand?.colorTitle}
                  textColor={brand?.colorDescription}
                >
                  <span
                    className="text-xs font-bold uppercase px-4 py-3 cursor-pointer"
                    style={{
                      backgroundColor: brand?.colorBackgroundButton,
                      color: brand?.colorTextButton,
                    }}
                    onClick={() => setStep(1)}
                  >
                    {promoData?.call_to_action}
                  </span>
                </CountdownTimer>
              )}
            </>
          ) : (
            <FormFlow
              colorFlow="color-glitch"
              tycFlow="tyc-glitch"
              btnFlow="btn-confirm-glitch"
              bestFlow="best-offer-glitch"
              bColor="b-glitch"
              stepChange={() => {}}
              final={onFinish}
              language={currentLanguage}
              disabled={isLoading}
              labels={{
                createAccount: translate("createAccount"),
                email: translate("email"),
                password: translate("password"),
                repeatPassword: translate("passwordFlow"),
                confirm: translate("confirmBtn"),
                join: translate("pricingJoin"),
              }}
            />
          )}
        </div>

        {step === 0 && (
          <img
            src={brand?.backgroundMobile}
            alt="Promotion"
            className="position-absolute z-0"
            style={{
              right: 0,
              bottom: 0,
              height: "100%",
              width: "100%",
              objectFit: "cover",
              zIndex: 1,
            }}
          />
        )}
      </div>
    </>
  );
};

export default withNotification(
  withModalsContext(withLanguage(withContext(PromotionScreen)))
);
