const { hostname } = window.location;
const variables = {
  myst: {
    prod: {
      description: "MYST - STEP INTO THE UNKNOWN.",
      bitmovin_license: "a6f88707-b6ef-4f27-8b3a-0e0d8fa18001",
      facebook: "themystcom.official",
      googlePlay:
        "https://play.google.com/store/apps/details?id=com.tribesmedia.myst",
      appStore:
        "https://apps.apple.com/co/app/myst-the-mystery-streaming/id1529141608",
      instagram: "myst.streaming",
      firebase: {
        api_key: "AIzaSyB9eoQPCBiFz5Yk0eh70g0LMHpgFXXCAqs",
        auth_domain: "myst-6d32f.firebaseapp.com",
        database_url: "https://myst-6d32f.firebaseio.com",
        project_id: "myst-6d32f",
        storage_bucket: "myst-6d32f.appspot.com",
        messaging_sender_id: "1063793424383",
        app_id: "1:1063793424383:web:040fe845300bdcc65859ca",
        measurement_id: "G-JV97JK88QE",
      },
      cloudfunctions_url: "https://us-central1-myst-6d32f.cloudfunctions.net/",
      stripe_api_key:
        "pk_live_51GzTsnIElqLJlzgQwVrMoLFspFepUnLH4Vd0bWjlmcDFrWbKAQuMJ9C4fXWK4cSVfQhN4m0Xy4YTicv8Z7usOlv60072XE1bBv",
      colors: {
        primary: "#5E00F6",
        trial: "#6969f7",
        alternative: "#680FFB",
      },
      googleTagManager: "GTM-WFWPNH6",
      api_url: "https://api.tribesmedia.com",
    },
    qa: {
      firebase: {
        database_url: "https://myst-6d32f.firebaseio.com",
        api_key: "AIzaSyCZVsqlmMv7iNOO41OnCY6HH5NqLB3HZrE",
        auth_domain: "themyst-development.firebaseapp.com",
        project_id: "themyst-development",
        storage_bucket: "themyst-development.appspot.com",
        messaging_sender_id: "326271359115",
        app_id: "1:326271359115:web:ecad125df82f772ba3dc8e",
        measurement_id: "G-SBF6G2MJCZ",
      },
      cloudfunctions_url:
        "https://us-central1-themyst-development.cloudfunctions.net/",
      stripe_api_key:
        "pk_test_51GzTsnIElqLJlzgQi1gYYpfJJUP81AjnaBm60fMjKS9hVx7YXICSfUpsn8EjmEg9uYi12FTYI4HQVQWJK2Ud18Tg00HwclkPSB",
      api_url: "https://api.tribesmedia.com",
    },
    dev: {
      cloudfunctions_url:
        "https://us-central1-themyst-development.cloudfunctions.net/",
      api_url: "https://api-tribes.keen.ar",
      firebase: {
        api_key: "AIzaSyB9eoQPCBiFz5Yk0eh70g0LMHpgFXXCAqs",
        auth_domain: "myst-6d32f.firebaseapp.com",
        database_url: "https://myst-6d32f.firebaseio.com",
        project_id: "myst-6d32f",
        storage_bucket: "myst-6d32f.appspot.com",
        messaging_sender_id: "1063793424383",
        app_id: "1:1063793424383:web:040fe845300bdcc65859ca",
        measurement_id: "G-JV97JK88QE",
      },
      stripe_api_key:
        "pk_test_51GzTsnIElqLJlzgQi1gYYpfJJUP81AjnaBm60fMjKS9hVx7YXICSfUpsn8EjmEg9uYi12FTYI4HQVQWJK2Ud18Tg00HwclkPSB",
    },
    local: {
      cloudfunctions_url:
        "https://us-central1-themyst-development.cloudfunctions.net/",
      api_url: "https://api-tribes.keen.ar",
      firebase: {
        api_key: "AIzaSyB9eoQPCBiFz5Yk0eh70g0LMHpgFXXCAqs",
        auth_domain: "myst-6d32f.firebaseapp.com",
        database_url: "https://myst-6d32f.firebaseio.com",
        project_id: "myst-6d32f",
        storage_bucket: "myst-6d32f.appspot.com",
        messaging_sender_id: "1063793424383",
        app_id: "1:1063793424383:web:040fe845300bdcc65859ca",
        measurement_id: "G-JV97JK88QE",
      },
    },
  },
  selego: {
    prod: {
      description: "SELEGO - STORIES FOR INDEPENDENT MINDS.",
      bitmovin_license: "a6f88707-b6ef-4f27-8b3a-0e0d8fa18001",
      facebook: "selego.official",
      googlePlay:
        "https://play.google.com/store/apps/details?id=com.tribesmedia.selego",
      appStore:
        "https://apps.apple.com/co/app/selego-curated-films-series/id1531775992",
      instagram: "selego.official",
      firebase: {
        api_key: "AIzaSyAQ1HiHqbK8BVhfpIAC4YJsdL3J7MGjETc",
        auth_domain: "selego-f4fbe.firebaseapp.com",
        database_url: "https://selego-f4fbe.firebaseio.com",
        project_id: "selego-f4fbe",
        storage_bucket: "selego-f4fbe.appspot.com",
        messaging_sender_id: "863740914697",
        app_id: "1:863740914697:web:9e22195d0320c0e40c9cd8",
        measurement_id: "G-B2X6PH3MZ9",
      },
      cloudfunctions_url:
        "https://us-central1-selego-f4fbe.cloudfunctions.net/",
      stripe_api_key:
        "pk_live_51GzTsnIElqLJlzgQwVrMoLFspFepUnLH4Vd0bWjlmcDFrWbKAQuMJ9C4fXWK4cSVfQhN4m0Xy4YTicv8Z7usOlv60072XE1bBv",
      colors: {
        primary: "#00574E",
        dark_orange: "#C92D23",
        alternative: "#EAEAEA",
        trial: "#E1B83B",
        background: "#E1DBD0",
      },
      googleTagManager: "GTM-PJD63QM",
      api_url: "https://api.tribesmedia.com",
    },
    qa: {
      firebase: {
        database_url: "https://selego-development-default-rtdb.firebaseio.com/",
        api_key: "AIzaSyDE6DureQRo2POWBMqMJGWzhhy0OGtw5Jk",
        auth_domain: "selego-development.firebaseapp.com",
        project_id: "selego-development",
        storage_bucket: "selego-development.appspot.com",
        messaging_sender_id: "1046881512661",
        app_id: "1:1046881512661:web:4446b4ff8866e9b073065c",
        measurement_id: "G-7CV9WW4HJM",
      },
      cloudfunctions_url:
        "https://us-central1-selego-development.cloudfunctions.net/",
      stripe_api_key:
        "pk_test_51GzTsnIElqLJlzgQi1gYYpfJJUP81AjnaBm60fMjKS9hVx7YXICSfUpsn8EjmEg9uYi12FTYI4HQVQWJK2Ud18Tg00HwclkPSB",
      api_url: "https://api.tribesmedia.com",
    },
    dev: {
      cloudfunctions_url:
        "https://us-central1-selego-development.cloudfunctions.net/",
      api_url: "https://api-tribes.keen.ar",
      firebase: {
        api_key: "AIzaSyAQ1HiHqbK8BVhfpIAC4YJsdL3J7MGjETc",
        auth_domain: "selego-f4fbe.firebaseapp.com",
        database_url: "https://selego-f4fbe.firebaseio.com",
        project_id: "selego-f4fbe",
        storage_bucket: "selego-f4fbe.appspot.com",
        messaging_sender_id: "863740914697",
        app_id: "1:863740914697:web:9e22195d0320c0e40c9cd8",
        measurement_id: "G-B2X6PH3MZ9",
      },
    },
    local: {
      cloudfunctions_url:
        "https://us-central1-selego-development.cloudfunctions.net/",
      api_url: "https://api-tribes.keen.ar",
      firebase: {
        api_key: "AIzaSyAQ1HiHqbK8BVhfpIAC4YJsdL3J7MGjETc",
        auth_domain: "selego-f4fbe.firebaseapp.com",
        database_url: "https://selego-f4fbe.firebaseio.com",
        project_id: "selego-f4fbe",
        storage_bucket: "selego-f4fbe.appspot.com",
        messaging_sender_id: "863740914697",
        app_id: "1:863740914697:web:9e22195d0320c0e40c9cd8",
        measurement_id: "G-B2X6PH3MZ9",
      },
    },
  },
  glitch: {
    prod: {
      description: "GLITCH - NOT FOR KIDS.",
      bitmovin_license: "a6f88707-b6ef-4f27-8b3a-0e0d8fa18001",
      facebook: "glitch.anim",
      instagram: "glitch_animation",
      googlePlay:
        "https://play.google.com/store/apps/details?id=com.tribesmedia.glitch",
      appStore: "https://apps.apple.com/co/app/glitch-animation/id1543427807",
      firebase: {
        api_key: "AIzaSyBAy0qf6BTfA3WTnIyKYASygmYtUNupIHg",
        auth_domain: "raff-27e4d.firebaseapp.com",
        database_url: "https://raff-27e4d.firebaseio.com",
        project_id: "raff-27e4d",
        storage_bucket: "raff-27e4d.appspot.com",
        messaging_sender_id: "1087467793421",
        app_id: "1:1087467793421:web:dfcbf14bc832854d18f944",
        measurement_id: "G-1HEGFQ5Z8S",
      },
      cloudfunctions_url: "https://us-central1-raff-27e4d.cloudfunctions.net/",
      stripe_api_key:
        "pk_live_51GzTsnIElqLJlzgQwVrMoLFspFepUnLH4Vd0bWjlmcDFrWbKAQuMJ9C4fXWK4cSVfQhN4m0Xy4YTicv8Z7usOlv60072XE1bBv",
      colors: {
        primary: "#FFFF00",
        alternative: "#FFFF00",
        trial: "#FFFF00",
        black: "#1A1A1A",
      },
      googleTagManager: "GTM-5SWNM53",
      api_url: "https://api.tribesmedia.com",
      year_promo_cupon: "QbE1ulNT",
    },
    qa: {
      firebase: {
        api_key: "AIzaSyBx_01ATjic1twZ_2E-QAaBpd-rl8WdXu0",
        auth_domain: "glitch-development-adbad.firebaseapp.com",
        database_url:
          "https://glitch-development-adbad-default-rtdb.europe-west1.firebasedatabase.app",
        project_id: "glitch-development-adbad",
        storage_bucket: "glitch-development-adbad.appspot.com",
        messaging_sender_id: "535798834102",
        app_id: "1:535798834102:web:8d11ae1282ebf73bc49717",
        measurement_id: "G-ECTGB63GYQ",
      },
      cloudfunctions_url:
        "https://us-central1-glitch-development-adbad.cloudfunctions.net/",
      stripe_api_key:
        "pk_test_51GzTsnIElqLJlzgQi1gYYpfJJUP81AjnaBm60fMjKS9hVx7YXICSfUpsn8EjmEg9uYi12FTYI4HQVQWJK2Ud18Tg00HwclkPSB",
      api_url: "https://api.tribesmedia.com",
      year_promo_cupon: "QbE1ulNT",
    },
    dev: {
      cloudfunctions_url:
        "https://us-central1-glitch-development-adbad.cloudfunctions.net/",
      api_url: "https://api-tribes.keen.ar",
      firebase: {
        api_key: "AIzaSyBAy0qf6BTfA3WTnIyKYASygmYtUNupIHg",
        auth_domain: "raff-27e4d.firebaseapp.com",
        database_url: "https://raff-27e4d.firebaseio.com",
        project_id: "raff-27e4d",
        storage_bucket: "raff-27e4d.appspot.com",
        messaging_sender_id: "1087467793421",
        app_id: "1:1087467793421:web:dfcbf14bc832854d18f944",
        measurement_id: "G-1HEGFQ5Z8S",
        year_promo_cupon: "QbE1ulNT",
      },
    },
    local: {
      cloudfunctions_url:
        "https://us-central1-glitch-development-adbad.cloudfunctions.net/",
      api_url: "https://api-tribes.keen.ar",
      firebase: {
        api_key: "AIzaSyBAy0qf6BTfA3WTnIyKYASygmYtUNupIHg",
        auth_domain: "raff-27e4d.firebaseapp.com",
        database_url: "https://raff-27e4d.firebaseio.com",
        project_id: "raff-27e4d",
        storage_bucket: "raff-27e4d.appspot.com",
        messaging_sender_id: "1087467793421",
        app_id: "1:1087467793421:web:dfcbf14bc832854d18f944",
        measurement_id: "G-1HEGFQ5Z8S",
        year_promo_cupon: "QbE1ulNT",
      },
    },
  },
  dev: {},
};

const sessionEnv = sessionStorage.getItem("env");

const env =
  hostname.includes("localhost") && sessionEnv
    ? sessionEnv
    : hostname.includes("glitch")
    ? "glitch"
    : hostname.includes("selego")
    ? "selego"
    : "myst";

const finalVariables =
  process.env.NODE_ENV === "development"
    ? {
        ...variables[env].prod,
        ...variables[env].qa,
        ...variables.dev,
        ...variables[env].dev,
        ...variables[env].local,
        env,
      }
    : hostname.includes("development")
    ? {
        ...variables[env].prod,
        ...variables[env].qa,
        env,
      }
    : { ...variables[env].prod, env };

const { primary, alternative, trial, dark_orange, black, background } =
  finalVariables.colors;

document.documentElement.style.setProperty("--primary-color", primary);
document.documentElement.style.setProperty("--alternative-color", alternative);
document.documentElement.style.setProperty(
  "--font-family",
  "'Helvetica Neue', sans-serif"
);
document.documentElement.style.setProperty("--black", black || "#000");
document.documentElement.style.setProperty("--trial-color", trial);
document.documentElement.style.setProperty("--dark-orange", dark_orange);
document.documentElement.style.setProperty(
  "--background-color",
  background || "#000"
);

export default finalVariables;
